@use '@/styles/utils/mixins.scss' as *;

.offeringWrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  .offeringContentContainer {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;

    @include tab() {
      gap: 3.75rem;
    }
  }

  .offeringContentDetails {
    max-width: 35rem;
    width: 100%;
  }

  .offeringList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    flex: 1 1 auto;

    @include tab() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .offeringCard {
    height: 100%;
    border-radius: 1.25rem;
    padding: 2.5rem 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    background-color: $white;
    border: 0 solid $white;
    -webkit-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    -moz-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    transition: all 0.2s ease-in-out;

    &:hover {
      border-color: $primaryAlt;
    }

    .offeringCardBody {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }

    .description {
      color: $neutral08;
    }
  }
}
